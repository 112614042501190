import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useParams } from "react-router-dom";
import {
  getFirestore,
  collection,
  getDocs,
  doc,
  query,
  setDoc,
  getDoc,
  orderBy,
  where,
} from "firebase/firestore";
import dayjs from "dayjs";
function CreateMachineProduct() {
  let params = useParams();
  let navigate = useNavigate();
  const machineId = params.machineId;
  const [data, setData] = useState({});
  const [products, setProducts] = useState({});
  const [channels, setChannels] = useState({});

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    setData({ ...data, [name]: value });
  };

  function fetchChannels() {
    getDocs(
      query(collection(getFirestore(), `machines/${machineId}/channels`))
    ).then(async (querySnapshot) => {
      let channels = {};

      // Map each product to a promise that resolves to the name
      const namePromises = querySnapshot.docs.map(async (doc) => {
        let data = doc.data();
        data.id = doc.id;
        if (data.status !== "Not connected") {
          if (data.status !== "In service with product") {
            data.warning = true;
          }
          channels[doc.id] = data;
        }
        return;
      });

      // Update the state with the resolved names
      setChannels(channels);

    });
  }
  const onClose = () => {
    navigate(`/machines/${machineId}/products`);
  };

  // Toevoegen van een product aan de machine
  const handleSubmit = async (e) => {
    e.preventDefault();
    const db = getFirestore();

    const formatLocation = (data.location && Array.isArray(data.location))
      ? data.location.map((location) => ({
        stock: 0,
        tray: location.tray ? parseInt(location.tray) : 0,
        channel: location.channel ? parseInt(location.channel) : 0,
        end_date: dayjs().toDate(),
        available: false,
        dispense_speed: 9,
        dispense_afterrun: 0,
      }))
      : [];

    // Ophalen van de productreferentie
    const productRef = doc(
      db,
      "products",
      data.product_reference || Object.keys(products[0]).id
    );

    try {
      // Ophalen van productgegevens uit Firestore
      const productSnap = await getDoc(productRef);
      const productData = productSnap.exists() ? productSnap.data() : {};

      // Ophalen van price en discount_price (fallbacks naar 0)
      const price = productData.base_price || 0;
      const discount_price = productData.discount_price || 0;

      // Opslaan in Firestore
      await setDoc(doc(db, `machines/${machineId}/products`, data.product_reference), {
        available_stock: 0,
        price,
        discount_price,
        display_order: parseInt(data.display_order),
        location: formatLocation,
        reference: productRef,
        ...(data.badge && { badge: data.badge }),
      });

      navigate(`/machines/${machineId}/products`);
    } catch (err) {
      console.error("Fout bij opslaan product:", err);
    }
  };

  useEffect(() => {
    getDocs(query(collection(getFirestore(), "products"), where("archived", "==", false), orderBy("name"))).then(
      async (querySnapshot) => {
        let products = {};
        querySnapshot.forEach((doc) => {
          let data = doc.data();
          data.id = doc.id;
          products[doc.id] = data;
        });

        // Filter out products that are already in the machine
        await getDocs(
          query(collection(getFirestore(), `machines/${machineId}/products`))
        ).then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            let data = doc.data();
            data.id = doc.id;
            delete products[doc.id];
          });
        });

        setProducts(products);
      }
    );
    fetchChannels();
  }, [machineId]);

  const handleLocationChange = (event, locationIndex, type) => {
    let value;
    let name;

    if (type === "Date") {
      value = new Date(event.$d);
      name = "end_date";
    } else {
      const target = event.target;
      value = target.value;
      name = target.name;
    }

    const location = data.location[locationIndex];
    if (type === "Date") {
      location[name] = value;
    } else {
      if (name === "traychannel") {
        const tray = value.split(" - ")[0];
        const channel = value.split(" - ")[1];
        location.tray = parseInt(tray);
        location.channel = parseInt(channel);
      } else
        if (name !== "available") {
          location[name] = parseInt(value);
        } else {
          location[name] = value;
        }
    }

    setData({
      ...data,
      location: [
        ...data.location.slice(0, locationIndex),
        location,
        ...data.location.slice(locationIndex + 1),
      ],
    });
  };

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle id="alert-dialog-title">
        Create a new product
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={(e) => handleSubmit(e)}>
          <Table size="small" sx={{ maxWidth: 800 }}>
            <TableBody>
              <TableRow>
                <TableCell>Product reference*</TableCell>
                <TableCell>
                  {products && Object.values(products).length > 0 ? (
                    <Select
                      style={{ width: "200px" }}
                      name="product_reference"
                      required
                      onChange={(e) => {
                        handleInputChange(e);
                      }}
                    >
                      <MenuItem selected disabled value="">
                        <em>None</em>
                      </MenuItem>
                      {Object.values(products).map((product) => (
                        <MenuItem key={product.id} value={product.id}>
                          {product.name}
                        </MenuItem>
                      ))}
                    </Select>
                  ) : (
                    <p>No products available or already in the machine</p>
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Locations*</TableCell>
                <TableCell>
                  <p>Tray and channel for each location</p>
                </TableCell>
              </TableRow>
              {data.location && data.location.length > 0 ? (
                data.location.map((location, index) => (
                  <TableRow key={index}>

                    <TableCell></TableCell>
                    <TableCell>
                      <b>Location {index + 1}</b>
                      Tray-channel*
                      <br />
                      {channels && Object.values(channels).length > 0 ? (
                        <Select
                          style={{ width: "200px" }}
                          name="traychannel"
                          type="number"
                          value={location.tray + " - " + location.channel}
                          required
                          onChange={(e) => {
                            handleLocationChange(e, index);
                          }}
                        >
                          {Object.values(channels).map((channel) => (
                            channel.status !== "Not connected" ? (
                              <MenuItem key={channel.id} value={channel.tray + " - " + channel.channel} >
                                {channel.tray + " - " + channel.channel}
                              </MenuItem>
                            ) : null
                          ))}
                        </Select>
                      ) : (
                        <p>No Trays available </p>
                      )}
                      <br />
                      <br />
                      <Button
                        onClick={() => {
                          const newArray = [...data.location];
                          newArray.splice(index, 1);
                          setData({ ...data, location: newArray });
                        }}>
                        Delete location
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <p>No locations added</p>
              )}
              <TableRow><TableCell></TableCell><TableCell> <Button
                onClick={() => {
                  setData({
                    ...data,
                    location: [
                      ...(data.location ? data.location : []),
                      { stock: 0, tray: 0, channel: 0, available: true, end_date: new Date(dayjs()), orientation: 3 },
                    ],
                  });
                }}
              >
                Add location
              </Button></TableCell></TableRow>
              <TableRow>
                <TableCell>New Badge</TableCell>
                <TableCell>
                  <TextField
                    style={{ width: "200px" }}
                    name="badge"
                    type="text"
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Display Order*</TableCell>
                <TableCell>
                  <TextField
                    style={{ width: "200px" }}
                    name="display_order"
                    required
                    type="number"
                    inputProps={{
                      step: "1",
                      min: "0"
                    }}
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Button
            style={{ marginTop: "20px" }}
            type="submit"
            variant="contained"
          >
            Create machine product
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default CreateMachineProduct;
