import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "../firebase.js";

import { getFirestore, collection, addDoc } from "firebase/firestore";

function CreateProduct() {
  let navigate = useNavigate();
  const [data, setData] = useState({});

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    setData({ ...data, [name]: value });
  };

  const onClose = () => {
    navigate("/products");
  };

  // State to store uploaded file
  const [imageForeground, setImageForeground] = useState("");
  const [imageBackground, setImageBackground] = useState("");
  const MAX_FILE_SIZE = 1.5 * 1024 * 1024;

  const validateFileSize = (file, setFileCallback, input) => {
    if (file && file.size > MAX_FILE_SIZE) {
      alert("Bestand is te groot! Kies een bestand kleiner dan 1.5 MB.");
      input.value = "";
      return;
    }
    setFileCallback(file);
  };

  const handleUpload = async (label) => {
    const file =
      label === "image_foreground" ? imageForeground : imageBackground;
    if (!file) {
      alert("Please upload an image first!");
    }
    const uniqueId = Math.random().toString(36).substring(2);
    const storageRef = ref(storage, `images/${uniqueId}_${file.name}`);

    // progress can be paused and resumed. It also exposes progress updates.
    // Receives the storage reference and the file to upload.
    const uploadTask = uploadBytesResumable(storageRef, file);

    await new Promise((resolve, reject) => {
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // const percent = Math.round(
          //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          // );
        },
        (err) => console.log(err),
        () => {
          console.log("uploading");
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            resolve();
            if (label === "image_foreground") {
              data.image_foreground = url;
            } else if (label === "image_background") {
              data.image_background = url;
            }
          });
        }
      );
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(data);

    await handleUpload("image_foreground");
    await handleUpload("image_background");
    // Create new product in the products document in firebase
    let productdata = {};
    productdata.archived = false;
    productdata.base_price = parseFloat(data.base_price);
    productdata.allergies = data.allergies;
    productdata.image_background = data.image_background;
    productdata.image_foreground = data.image_foreground
    productdata.ingredients = data.ingredients;
    productdata.name = data.name;
    productdata.serving = data.serving;
    productdata.summary = data.summary;
    productdata.tagline = data.tagline;
    productdata.tags = [];
    if (data.tag1) {
      productdata.tags.push(data.tag1);
    }
    if (data.tag2) {
      productdata.tags.push(data.tag2);
    }
    if (data.tag3) {
      productdata.tags.push(data.tag3);
    }
    productdata.nutrition_facts = {};
    productdata.nutrition_facts.carbohydrate = [data.carbohydrate, data.carbohydrate_portion];
    productdata.nutrition_facts.energy = [data.energy, data.energy_portion];
    productdata.nutrition_facts.carbohydrate_sugar = [data.carbohydrate_sugar, data.carbohydrate_sugar_portion];
    productdata.nutrition_facts.fats = [data.fats, data.fats_portion];
    productdata.nutrition_facts.fats_saturated = [data.fats_saturated, data.fats_saturated_portion];
    productdata.nutrition_facts.fibre = [data.fibre, data.fibre_portion];
    productdata.nutrition_facts.protein = [data.protein, data.protein_portion];
    productdata.nutrition_facts.salt = [data.salt, data.salt_portion];



    await addDoc(collection(getFirestore(), "products"), productdata)
      .catch((err) => {
        console.log(err);
      })
      .then(() => {
        navigate("/products");
      });
  };

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle id="alert-dialog-title">
        Create a new product
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={(e) => handleSubmit(e)}>
          <Table size="small" sx={{ maxWidth: 800 }}>
            <TableBody>
              <TableRow>
                <TableCell>Name*</TableCell>
                <TableCell>
                  <TextField
                    style={{ width: "200px" }}
                    name="name"
                    type="text"
                    required
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Summary*</TableCell>
                <TableCell>
                  <TextField
                    style={{ width: "200px" }}
                    name="summary"
                    type="text"
                    required
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Tagline*</TableCell>
                <TableCell>
                  <TextField
                    style={{ width: "200px" }}
                    name="tagline"
                    type="text"
                    required
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Tag1</TableCell>
                <TableCell>
                  <TextField
                    style={{ width: "200px" }}
                    name="tag1"
                    type="text"

                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Tag2</TableCell>
                <TableCell>
                  <TextField
                    style={{ width: "200px" }}
                    name="tag2"
                    type="text"

                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Tag3</TableCell>
                <TableCell>
                  <TextField
                    style={{ width: "200px" }}
                    name="tag3"
                    type="text"

                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                  />
                </TableCell>
              </TableRow>
              {/* <TableRow>
                <TableCell>Category</TableCell>
                <TableCell>
                  <Select
                    style={{ width: "200px" }}
                    name="category"
                    required
                    defaultValue="bites"
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                  >
                    <MenuItem value="bites">bites</MenuItem>
                    <MenuItem value="drinks">drinks</MenuItem>
                    <MenuItem value="salads">salads</MenuItem>
                    <MenuItem value="wraps">wraps</MenuItem>
                    <MenuItem value="cutlery">cutlery</MenuItem>
                  </Select>
                </TableCell>
              </TableRow> */}
              <TableRow>
                <TableCell>Base price*</TableCell>
                <TableCell>
                  <TextField
                    style={{ width: "200px" }}
                    name="base_price"
                    type="number"
                    required
                    inputProps={{
                      step: "0.01",
                      min: "0"
                    }}
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                  />
                </TableCell>
              </TableRow>
              {/* <TableRow>
                <TableCell>Discount price</TableCell>
                <TableCell>
                  <TextField
                    style={{ width: "200px" }}
                    name="discount_price"
                    type="number"
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                  />
                </TableCell>
              </TableRow> */}
              <TableRow>
                <TableCell>Allergies*</TableCell>
                <TableCell>
                  <TextField
                    style={{ width: "200px" }}
                    name="allergies"
                    type="text"
                    required
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Ingredients*</TableCell>
                <TableCell>
                  <TextField
                    style={{ width: "200px" }}
                    name="ingredients"
                    type="text"
                    required
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Serving*</TableCell>
                <TableCell>
                  <TextField
                    style={{ width: "200px" }}
                    name="serving"
                    type="text"
                    required
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Image foreground*</TableCell>
                <TableCell>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div>
                      <input
                        style={{ marginBottom: "10px" }}
                        type="file"
                        required
                        onChange={(e) => validateFileSize(e.target.files[0], setImageForeground, e.target)}
                        accept="image/*"
                      />
                    </div>
                  </div>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Image background*</TableCell>
                <TableCell>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div>
                      <input
                        style={{ marginBottom: "10px" }}
                        type="file"
                        required
                        onChange={(e) => validateFileSize(e.target.files[0], setImageBackground, e.target)}
                        accept="image/*"
                      />
                    </div>
                  </div>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Nutrition facts</TableCell>
                <TableCell>per 100g</TableCell>
                <TableCell>per portion</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Energy*</TableCell>
                <TableCell>
                  <TextField
                    style={{ width: "200px" }}
                    name="energy"
                    type="text"
                    required
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    style={{ width: "200px" }}
                    name="energy_portion"
                    type="text"
                    required
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Carbohydrate*</TableCell>
                <TableCell>
                  <TextField
                    style={{ width: "200px" }}
                    name="carbohydrate"
                    type="text"
                    required
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    style={{ width: "200px" }}
                    name="carbohydrate_portion"
                    type="text"
                    required
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Carbohydrate sugar*</TableCell>
                <TableCell>
                  <TextField
                    style={{ width: "200px" }}
                    name="carbohydrate_sugar"
                    type="text"
                    required
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    style={{ width: "200px" }}
                    name="carbohydrate_sugar_portion"
                    type="text"
                    required
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Fats*</TableCell>
                <TableCell>
                  <TextField
                    style={{ width: "200px" }}
                    name="fats"
                    type="text"
                    required
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    style={{ width: "200px" }}
                    name="fats_portion"
                    type="text"
                    required
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>*Fats saturated</TableCell>
                <TableCell>
                  <TextField
                    style={{ width: "200px" }}
                    name="fats_saturated"
                    type="text"
                    required
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    style={{ width: "200px" }}
                    name="fats_saturated_portion"
                    type="text"
                    required
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Fibre*</TableCell>
                <TableCell>
                  <TextField
                    style={{ width: "200px" }}
                    name="fibre"
                    type="text"
                    required
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    style={{ width: "200px" }}
                    name="fibre_portion"
                    type="text"
                    required
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Protein*</TableCell>
                <TableCell>
                  <TextField
                    style={{ width: "200px" }}
                    type="text"
                    name="protein"
                    required
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    style={{ width: "200px" }}
                    name="protein_portion"
                    type="text"
                    required
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Salt*</TableCell>
                <TableCell>
                  <TextField
                    style={{ width: "200px" }}
                    name="salt"
                    type="text"
                    required
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    style={{ width: "200px" }}
                    name="salt_portion"
                    type="text"
                    required
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Button
            style={{ marginTop: "20px" }}
            type="submit"
            variant="contained"
          >
            Create product
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default CreateProduct;
