import {
  getFirestore,
  doc,
  updateDoc,
  deleteField,
  onSnapshot,
  getDocs,
  collection,
  query,
  orderBy,
  limit,
  where,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import {
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Card, CardContent
} from "@mui/material";
import Alert from '@mui/material/Alert';
import { useParams } from "react-router-dom";
import MachineMenu from './MachineMenu';

function MachineDetail() {
  let params = useParams();
  const machineId = params.machineId;
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [products, setProducts] = useState({});
  const [transactions, setTransactions] = useState({});
  const [machine, setMachine] = useState(null);
  const [fillers, setFillers] = useState([]);

  const [warning, setWarning] = useState("Machine status OK");
  const [machineSeverity, setMachineSeverity] = useState("success");

  const [newMachineLocation, setNewMachineLocation] = useState("");

  const firestore = getFirestore();

  const handleClose = () => {
    setOpen(false);
  };

  const handleEditClose = () => {
    setEditOpen(false);
  }

  const handleMachineChange = (e) => {
    setNewMachineLocation(e.target.value);
  };

  const handleSaveMachine = async () => {
    if (!machine || !newMachineLocation.trim()) return;
    const machineRef = doc(firestore, "machines", machineId);
    await updateDoc(machineRef, { location: newMachineLocation });
    setNewMachineLocation("");
    setEditOpen(false);
  };

  function fetchMachineData() {
    onSnapshot(doc(firestore, "machines", machineId), (docSnap) => {
      if (!docSnap.exists()) return;

      const machineData = docSnap.data();
      let newWarning = "Machine status OK";
      let warning = false;
      let error = false;

      const conditions = [
        { check: machineData.air_temperature > 7, message: "Temperature > 7 degrees", level: "warning" },
        { check: machineData.temperature_alarm, message: "Temperature alarm", level: "error" },
        { check: machineData.machine_availability !== "Machine Free", message: machineData.machine_availability, level: "warning" },
        { check: machineData.machine_door_open, message: "Machine door open", level: "warning" },
        { check: !machineData.machine_enabled, message: "Machine disabled", level: "error" },
        { check: !machineData.machine_in_service, message: "Machine not in service", level: "error" },
        { check: Date.now() - machineData.last_update.toDate() > 60 * 60 * 1000, message: "No update from machine", level: "warning" },
        { check: machineData.controller_status !== "online", message: "Machine offline", level: "error" },
        { check: machineData.overlay_active, message: "Machine is closed because of settings", level: "warning" }
      ];

      for (const condition of conditions) {
        if (condition.check) {
          newWarning = condition.message;
          if (condition.level === "error") {
            error = true;
          } else if (condition.level === "warning") {
            warning = true;
          }
        }
      }

      setWarning(newWarning);
      if (error) {
        setMachineSeverity("error");
      } else if (warning) {
        setMachineSeverity("warning");
      } else {
        setMachineSeverity("success");
      }

      setMachine(machineData);
    });
  }

  async function fetchFillerData() {
    try {
      const usersRef = collection(firestore, "users");

      const q = query(usersRef, where("roles", "array-contains", "filler"));
      const querySnapshot = await getDocs(q);

      const users = [];
      querySnapshot.forEach((doc) => {
        users.push(doc.data());
      });

      return users;
    } catch (error) {
      console.error("Error fetching users: ", error);
      return [];
    }
  }

  function getProducts() {
    getDocs(collection(getFirestore(), 'products')).then((querySnapshot) => {
      let products = {};
      querySnapshot.forEach((doc) => {
        let data = doc.data();
        data.id = doc.id;
        products[doc.id] = data;
      });
      setProducts(products);
    });
  }

  function getLatestTransactions() {
    onSnapshot(query(collection(getFirestore(), `machines/${machineId}/transactions`), orderBy("created_at", "desc"), limit(100)), (querySnapshot) => {
      let transactionsData = {};
      querySnapshot.forEach((doc) => {
        let data = doc.data();
        data.id = doc.id;
        data.severity = "success";
        if (data.status == "dispensing_failed") {
          data.severity = "error";
        } else if (data.status !== "transaction_done") {
          data.severity = "warning";
        }
        transactionsData[doc.id] = data;
      });
      setTransactions(transactionsData);
    });
  }

  const getFillers = async () => {
    const fillerData = await fetchFillerData();
    setFillers(fillerData);
  };

  useEffect(() => {
    fetchMachineData();
    getProducts();
    getLatestTransactions();
    getFillers();

  }, [machineId]);

  const onResetTemperatureAlarm = async () => {
    await updateDoc(
      doc(getFirestore(), `machines/${machineId}`),
      { temperature_alarm: false, air_temperature_alarm_start: deleteField() }
    );
  };

  function machineinfo() {
    if (!machine) return null;
    return (
      <div>
        <Alert severity={machineSeverity}>{warning}</Alert>
        <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
          <div style={{ marginRight: '10px' }}>Location: {machine.location}</div>
          <Button
            onClick={() => setEditOpen(true)}
            sx={{
              backgroundColor: "primary.main",
              color: "white",
              marginLeft: "auto",
              "&:hover": {
                backgroundColor: "primary.dark",
              },
            }}
            variant="contained"
          >
            Edit machine location
          </Button>
        </div>
        <div>
          Name: {machine.name} <br />
          Machine number: {machine.number} <br />
          air_temperature: {machine.air_temperature} <br />
          {machine.temperature_alarm && (
            <b>
              Temperature alarm active since: {machine.air_temperature_alarm_start.toDate().toLocaleString()}<br />
            </b>
          )}
          {machine.temperature_alarm && (
            <Chip
              label="Reset Alarm"
              onClick={() => setOpen(true)}
            />
          )}
          <br />
          Controller_status: {machine.controller_status} <br />
          Controller_version: {machine.controller_version} <br />
          last restart: {machine.last_restart.toDate().toLocaleString()} <br />
          last update: {machine.last_update.toDate().toLocaleString()} <br />
          machine_availability: {machine.machine_availability} <br />
          machine_door_open: {machine.machine_door_open ? <b>"OPEN"</b> : "closed"} <br />
          machine_enabled: {!machine.machine_enabled ? <b>"NO - ERROR"</b> : "yes"} <br />
          machine_in_service: {!machine.machine_in_service ? <b>"NO - ERROR"</b> : "yes"} <br />
          machine_status: {machine.machine_status} <br />
        </div>
        <div>
          <h3>Fillers</h3>
          <ul>
            {fillers.length === 0 ? (
              <li>No fillers found.</li>
            ) : (
              fillers.map((filler, index) => (
                <li key={index}>{filler.email}</li>
              ))
            )}
          </ul>
        </div>
      </div>
    );
  }

  return (
    <div>
      {MachineMenu(machine, machineId)}
      <Card>
        <CardContent>
          {machine ? machineinfo() : "Loading..."}
        </CardContent>
      </Card>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <b style={{ marginLeft: 16 }}>Last 100 transactions:</b>
            <TableRow>
              <TableCell>Transaction ID</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Products</TableCell>
              <TableCell>ProductLocation</TableCell>
              <TableCell>Payter ID</TableCell>
              <TableCell>Created at</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.values(transactions).map((transaction) => (
              <TableRow key={transaction.id}>
                <TableCell>{transaction.transactionId}</TableCell>
                <TableCell>&euro;{transaction.price === undefined ? "-.--" : transaction.price.toFixed(2)}</TableCell>
                <TableCell>{transaction.products[0] ? products[transaction.products[0].id]?.name + " " + transaction.products[0].error_status + " " + transaction.products[0].extended_status : ""}</TableCell>
                <TableCell>{transaction.products[0] ? transaction.products[0].tray + "-" + transaction.products[0].channel : ""}</TableCell>
                <TableCell>{transaction.payter_sessionId}</TableCell>
                <TableCell>{transaction.created_at.toDate().toLocaleString()}</TableCell>
                <TableCell><Alert severity={transaction.severity}>{transaction.status}</Alert></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        open={editOpen}
        onClose={handleClose}
        sx={{ '& .MuiDialog-paper': { width: '500px', padding: '20px' } }}
      >
        <DialogTitle sx={{ textAlign: 'center' }}>Edit Machine Location</DialogTitle>
        <TextField
          defaultValue={machine?.location}
          onChange={handleMachineChange}
          fullWidth
          sx={{ mt: 2 }}
        />
        <DialogActions>
          <Button onClick={handleSaveMachine} disabled={!newMachineLocation.trim()}>
            Save
          </Button>
          <Button onClick={handleEditClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Reset Temperature Alarm?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            If products are ok, and sales can be resumed, you should reset the temperature alarm.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={onResetTemperatureAlarm}>Reset</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default MachineDetail;
