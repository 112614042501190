import {
    getFirestore,
    collection,
    onSnapshot,
    query,
    deleteDoc,
    doc,
    orderBy,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import {
    Chip,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
} from "@mui/material";
import { Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router'
import { ref,deleteObject } from "firebase/storage";
import { storage } from "../firebase.js";

function Screens() {
    const [open, setOpen] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [screens, setScreens] = useState([]);
    const navigate = useNavigate();



    const handleClose = () => {
        setOpen(false);
    };

    const location = useLocation()


    function fetchData() {

        var sub = onSnapshot(query(
            collection(getFirestore(), "screens"),
            orderBy("created", "asc")
        ), (querySnapshot) => {
            let screens = {};
            querySnapshot.forEach((doc) => {
                let data = doc.data();
                data.id = doc.id;
                screens[doc.id] = data;
            });
            console.log(screens);
            setScreens(screens);
        });


        //   getDocs(query(collection(getFirestore(), "screens"),orderBy("last_update", 'desc'))).then(
        //     (querySnapshot) => {
        //       let screens = [];
        //       querySnapshot.forEach((doc) => {
        //         let data = doc.data();
        //         data.id = doc.id;
        //         screens[doc.id] = data;
        //       });


        //     }
        //   );
    }

    useEffect(() => {
        fetchData();
    }, [location.key])

    useEffect(() => {
        fetchData();
    }, []);

    const onDelete = async (id) => {



        const storageRef = ref(storage, screens[id].storage_ref);
        console.log(screens[id].storage_ref);

        // Delete the file
        deleteObject(storageRef).then(() => {
            // File deleted successfully
        }).catch((error) => {
            // Uh-oh, an error occurred!
        });
        await deleteDoc(doc(getFirestore(), "screens", id));
        setScreens((prev) => {
            let newScreens = { ...prev };
            delete newScreens[id];
            return newScreens;
        });




    };

    return (
        <div>
            <Button
                style={{ marginBottom: "20px" }}
                onClick={() => navigate("create")}
                variant="contained"
            >
                Add new screen
            </Button>
            
            <h2>Screensaver screens</h2>On this page you can add screens that can be used in the screensaver. <br />
            Each image should have a format of 1080x1920 pixels. Max size 2 mb.
            <hr />
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Screen</TableCell>
                            {/* <TableCell>Discount Price</TableCell> */}
                            {/* <TableCell>Category</TableCell> */}
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>

                        {Object.values(screens).map((screen) => (
                            <TableRow key={screen.id}>
                                <TableCell>{screen.id}</TableCell>
                                <TableCell>{screen.name}</TableCell>
                                <TableCell><img
                                    width="100px"
                                    srcSet={`${screen['url']}?w=270&h=480&fit=crop&auto=format&dpr=2 2x`}
                                    src={`${screen['url']}?w=270&h=480&fit=crop&auto=format`}
                                    alt={screen['name']}
                                    loading="lazy"
                                /></TableCell>

                                <TableCell>

                                    {<Chip
                                        label="Delete"
                                        onClick={() => {
                                            setDeleteId(screen.id);
                                            setOpen(true);
                                        }}
                                    />}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Outlet />
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Delete screen?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this screen?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button
                        onClick={() => {
                            onDelete(deleteId);
                            handleClose();
                        }}
                        autoFocus
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default Screens;
