import React, { useState } from "react";
import {
  getAuth,
  signInWithEmailAndPassword,
  setPersistence,
  browserSessionPersistence,
} from "firebase/auth";
import { Container, Paper, TextField } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Alert } from "@mui/lab";

function SignIn() {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);

  const submit = (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    const auth = getAuth();
    setPersistence(auth, browserSessionPersistence)
      .then(() => {
        return signInWithEmailAndPassword(auth, email, password);
      })
      .catch((error) => {
        setError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
    return false;
  };

  return (
    <Container maxWidth={"sm"}>
      <br />
      <br />
      <Paper sx={{ p: 2 }}>
        <h1>Sign in</h1>

        {error && <Alert severity="error">{error}</Alert>}

        <form onSubmit={submit}>
          <TextField
            id="email"
            label="E-mail"
            variant="standard"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <br />
          <br />
          <TextField
            id="password"
            label="Password"
            variant="standard"
            fullWidth
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <br />
          <br />
          <br />
          <LoadingButton
            type="submit"
            onClick={submit}
            variant="contained"
            loading={loading}
          >
            Submit
          </LoadingButton>
        </form>
      </Paper>
    </Container>
  );
}

export default SignIn;
