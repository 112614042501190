import React, { useState, useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { Container } from "@mui/material";
import { auth } from "./firebase";
import App from "./App";
import SignIn from "./page/SignIn";

const AppContainer = () => {
  const [initializing, setInitializing] = useState(true);
  const [user, setUser] = useState(null);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        // User is signed out
        setUser(null);
      }
      if (initializing) setInitializing(false);
    });
  }, [initializing]);

  if (initializing) return null;

  return <Container>{user === null ? <SignIn /> : <App />}</Container>;
};

export default AppContainer;
