import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Chip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useParams, useNavigate } from "react-router-dom";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "../firebase.js";

import { doc, getFirestore, updateDoc, getDoc } from "firebase/firestore";

function EditProduct() {
  let params = useParams();
  let navigate = useNavigate();
  let [data, setData] = useState(null);
  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    setData({ ...data, [name]: value });
  };

  useEffect(() => {
    getDoc(doc(getFirestore(), "products", params.productId)).then(
      (docSnap) => {
        setData(docSnap.data());
      }
    );
  }, [params.productId]);

  const onClose = () => {
    navigate("/products");
  };

  // State to store uploaded file
  const [imageForeground, setImageForeground] = useState("");
  const [imageBackground, setImageBackground] = useState("");
  const MAX_FILE_SIZE = 1.5 * 1024 * 1024;

  const validateFileSize = (file, setFileCallback, input) => {
    if (file && file.size > MAX_FILE_SIZE) {
      alert("Bestand is te groot! Kies een bestand kleiner dan 1.5 MB.");
      input.value = "";
      return;
    }
    setFileCallback(file);
  };

  const handleUpload = async (label) => {
    const file =
      label === "image_foreground" ? imageForeground : imageBackground;
    if (!file) {
      return;
    }
    const uniqueId = Math.random().toString(36).substring(2);
    const storageRef = ref(storage, `images/${uniqueId}_${file.name}`);

    // progress can be paused and resumed. It also exposes progress updates.
    // Receives the storage reference and the file to upload.
    const uploadTask = uploadBytesResumable(storageRef, file);

    await new Promise((resolve, reject) => {
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // const percent = Math.round(
          //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          // );
        },
        (err) => console.log(err),
        () => {
          console.log("uploading");
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            resolve();
            if (label === "image_foreground") {
              data.image_foreground = url;
            } else if (label === "image_background") {
              data.image_background = url;
            }
          });
        }
      );
    });
  };

  const handleSubmit = async () => {
    console.log(data);

    await Promise.all([handleUpload("image_foreground"), handleUpload("image_background")]);

    const {
      base_price, allergies, image_background, image_foreground, ingredients, name,
      serving, summary, tagline, tags = [], tag1, tag2, tag3,
      carbohydrate, carbohydrate_portion, carbohydrate_sugar, carbohydrate_sugar_portion,
      energy, energy_portion, fats, fats_portion, fats_saturated, fats_saturated_portion,
      fibre, fibre_portion, protein, protein_portion, salt, salt_portion,
      nutrition_facts = {}
    } = data;

    // Helper function to assign values to arrays
    const assignToArray = (array = [], index, value) => {
      if (value !== undefined) array[index] = value;
      return array;
    };

    const productData = {
      base_price: parseFloat(base_price),
      allergies,
      image_background,
      image_foreground,
      ingredients,
      name,
      serving,
      summary,
      tagline,
      tags: assignToArray(assignToArray(assignToArray(tags, 0, tag1), 1, tag2), 2, tag3),
      nutrition_facts: {
        ...nutrition_facts,
        carbohydrate: assignToArray(nutrition_facts.carbohydrate, 0, carbohydrate),
        carbohydrate_portion: assignToArray(nutrition_facts.carbohydrate, 1, carbohydrate_portion),
        carbohydrate_sugar: assignToArray(nutrition_facts.carbohydrate_sugar, 0, carbohydrate_sugar),
        carbohydrate_sugar_portion: assignToArray(nutrition_facts.carbohydrate_sugar, 1, carbohydrate_sugar_portion),
        energy: assignToArray(nutrition_facts.energy, 0, energy),
        energy_portion: assignToArray(nutrition_facts.energy, 1, energy_portion),
        fats: assignToArray(nutrition_facts.fats, 0, fats),
        fats_portion: assignToArray(nutrition_facts.fats, 1, fats_portion),
        fats_saturated: assignToArray(nutrition_facts.fats_saturated, 0, fats_saturated),
        fats_saturated_portion: assignToArray(nutrition_facts.fats_saturated, 1, fats_saturated_portion),
        fibre: assignToArray(nutrition_facts.fibre, 0, fibre),
        fibre_portion: assignToArray(nutrition_facts.fibre, 1, fibre_portion),
        protein: assignToArray(nutrition_facts.protein, 0, protein),
        protein_portion: assignToArray(nutrition_facts.protein, 1, protein_portion),
        salt: assignToArray(nutrition_facts.salt, 0, salt),
        salt_portion: assignToArray(nutrition_facts.salt, 1, salt_portion),
      }
    };

    console.log(productData);

    await updateDoc(doc(getFirestore(), "products", params.productId), productData)
      .then(() => {
        setData(data);
        navigate("/products");
      });
  };

  console.log(data);
  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle id="alert-dialog-title">
        Edit {data ? data["name"] : ""}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {data ? (
          <Table size="small" sx={{ maxWidth: 400 }}>
            <TableBody>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>
                  <TextField
                    style={{ width: "200px" }}
                    name="name"
                    defaultValue={data.name}
                    type="text"
                    required
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Summary</TableCell>
                <TableCell>
                  <TextField
                    style={{ width: "200px" }}
                    name="summary"
                    defaultValue={data['summary']}
                    type="text"
                    required
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Tagline</TableCell>
                <TableCell>
                  <TextField
                    style={{ width: "200px" }}
                    name="tagline"
                    type="text"
                    defaultValue={data['tagline']}
                    required
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Tag1</TableCell>
                <TableCell>
                  <TextField
                    style={{ width: "200px" }}
                    name="tag1"
                    type="text"
                    defaultValue={data['tags'][0]}
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Tag2</TableCell>
                <TableCell>
                  <TextField
                    style={{ width: "200px" }}
                    name="tag2"
                    type="text"
                    defaultValue={data['tags'][1]}

                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Tag3</TableCell>
                <TableCell>
                  <TextField
                    style={{ width: "200px" }}
                    name="tag3"
                    type="text"
                    defaultValue={data['tags'][2]}
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                  />
                </TableCell>
              </TableRow>
              {/* <TableRow>
                <TableCell>Category</TableCell>
                <TableCell>
                  <Select
                    style={{ width: "200px" }}
                    name="category"
                    required
                    defaultValue="bites"
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                  >
                    <MenuItem value="bites">bites</MenuItem>
                    <MenuItem value="drinks">drinks</MenuItem>
                    <MenuItem value="salads">salads</MenuItem>
                    <MenuItem value="wraps">wraps</MenuItem>
                    <MenuItem value="cutlery">cutlery</MenuItem>
                  </Select>
                </TableCell>
              </TableRow> */}
              <TableRow>
                <TableCell>Base price</TableCell>
                <TableCell>
                  <TextField
                    style={{ width: "200px" }}
                    name="base_price"
                    defaultValue={data['base_price']}
                    type="number"
                    required
                    inputProps={{
                      step: "0.01",
                      min: "0"
                    }}
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                  />
                </TableCell>
              </TableRow>
              {/* <TableRow>
                <TableCell>Discount price</TableCell>
                <TableCell>
                  <TextField
                    style={{ width: "200px" }}
                    name="discount_price"
                    type="number"
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                  />
                </TableCell>
              </TableRow> */}
              <TableRow>
                <TableCell>Allergies</TableCell>
                <TableCell>
                  <TextField
                    style={{ width: "200px" }}
                    name="allergies"
                    defaultValue={data['allergies']}
                    type="text"
                    required
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Ingredients</TableCell>
                <TableCell>
                  <TextField
                    style={{ width: "200px" }}
                    name="ingredients"
                    defaultValue={data['ingredients']}
                    type="text"
                    required
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Serving</TableCell>
                <TableCell>
                  <TextField
                    style={{ width: "200px" }}
                    name="serving"
                    defaultValue={data['serving']}
                    type="text"
                    required
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Image foreground</TableCell>
                <TableCell>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                      width="100px"
                      srcSet={`${data['image_foreground']}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                      src={`${data['image_foreground']}?w=164&h=164&fit=crop&auto=format`}
                      alt={data['image_foreground']}
                      loading="lazy"
                    />
                    <div>
                      <input
                        style={{ marginBottom: "10px" }}
                        type="file"
                        onChange={(e) => validateFileSize(e.target.files[0], setImageForeground, e.target)}
                        accept="/image/*"
                      />
                    </div>
                  </div>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Image background</TableCell>
                <TableCell>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                      width="100px"
                      srcSet={`${data['image_background']}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                      src={`${data['image_background']}?w=164&h=164&fit=crop&auto=format`}
                      alt={data['image_background']}
                      loading="lazy"
                    />
                    <div>
                      <input
                        style={{ marginBottom: "10px" }}
                        type="file"
                        onChange={(e) => validateFileSize(e.target.files[0], setImageBackground, e.target)}
                        accept="/image/*"
                      />
                    </div>
                  </div>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Nutrition facts</TableCell>
                <TableCell>per 100g</TableCell>
                <TableCell>per portion</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Energy</TableCell>
                <TableCell>
                  <TextField
                    style={{ width: "200px" }}
                    name="energy"
                    type="text"
                    defaultValue={data['nutrition_facts']['energy'][0]}
                    required
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    style={{ width: "200px" }}
                    name="energy_portion"
                    defaultValue={data['nutrition_facts']['energy'][1]}
                    type="text"
                    required
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Carbohydrate</TableCell>
                <TableCell>
                  <TextField
                    style={{ width: "200px" }}
                    name="carbohydrate"
                    defaultValue={data['nutrition_facts']['carbohydrate'][0]}
                    type="text"
                    required
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    style={{ width: "200px" }}
                    name="carbohydrate_portion"
                    defaultValue={data['nutrition_facts']['carbohydrate'][1]}
                    type="text"
                    required
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Carbohydrate sugar</TableCell>
                <TableCell>
                  <TextField
                    style={{ width: "200px" }}
                    name="carbohydrate_sugar"
                    defaultValue={data['nutrition_facts']['carbohydrate_sugar'][0]}
                    type="text"
                    required
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    style={{ width: "200px" }}
                    name="carbohydrate_sugar_portion"
                    defaultValue={data['nutrition_facts']['carbohydrate_sugar'][1]}
                    type="text"
                    required
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Fats</TableCell>
                <TableCell>
                  <TextField
                    style={{ width: "200px" }}
                    name="fats"
                    type="text"
                    defaultValue={data['nutrition_facts']['fats'][0]}
                    required
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    style={{ width: "200px" }}
                    name="fats_portion"
                    defaultValue={data['nutrition_facts']['fats'][1]}
                    type="text"
                    required
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Fats saturated</TableCell>
                <TableCell>
                  <TextField
                    style={{ width: "200px" }}
                    name="fats_saturated"
                    type="text"
                    defaultValue={data['nutrition_facts']['fats_saturated'][0]}
                    required
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    style={{ width: "200px" }}
                    name="fats_saturated_portion"
                    defaultValue={data['nutrition_facts']['fats_saturated'][1]}
                    type="text"
                    required
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Fibre</TableCell>
                <TableCell>
                  <TextField
                    style={{ width: "200px" }}
                    name="fibre"
                    type="text"
                    required
                    defaultValue={data['nutrition_facts']['fibre'][0]}
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    style={{ width: "200px" }}
                    name="fibre_portion"
                    type="text"
                    required
                    defaultValue={data['nutrition_facts']['fibre'][1]}
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Protein</TableCell>
                <TableCell>
                  <TextField
                    style={{ width: "200px" }}
                    type="text"
                    name="protein"
                    defaultValue={data['nutrition_facts']['protein'][0]}
                    required
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    style={{ width: "200px" }}
                    name="protein_portion"
                    type="text"
                    required
                    defaultValue={data['nutrition_facts']['protein'][1]}
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Salt</TableCell>
                <TableCell>
                  <TextField
                    style={{ width: "200px" }}
                    name="salt"
                    type="text"
                    required
                    defaultValue={data['nutrition_facts']['salt'][0]}
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    style={{ width: "200px" }}
                    name="salt_portion"
                    type="text"
                    required
                    defaultValue={data['nutrition_facts']['salt'][1]}
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                  />
                </TableCell>
              </TableRow>
              {/* {data &&
              Object.keys(data).map((label) => (
                <TableRow key={label}>
                  <TableCell>{label}</TableCell>
                  <TableCell>
                    {label === "category" ? (
                      <Select
                        style={{ width: "200px" }}
                        value={data[label]}
                        defaultValue={data[label]}
                      >
                        <MenuItem value="bites">bites</MenuItem>
                        <MenuItem value="drinks">drinks</MenuItem>
                        <MenuItem value="salads">salads</MenuItem>
                        <MenuItem value="wraps">wraps</MenuItem>
                        <MenuItem value="cutlery">cutlery</MenuItem>
                      </Select>
                    ) : label === "image_foreground" ||
                      label === "image_background" ? (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <img
                          width="100px"
                          srcSet={`${data[label]}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                          src={`${data[label]}?w=164&h=164&fit=crop&auto=format`}
                          alt={data[label]}
                          loading="lazy"
                        />
                        <div>
                          <input
                            style={{ marginBottom: "10px" }}
                            type="file"
                            onChange={(e) => {
                              label === "image_foreground"
                                ? setImageForeground(e.target.files[0])
                                : setImageBackground(e.target.files[0]);
                            }}
                            accept="/image/*"
                          />
                        </div>
                      </div>
                    ) : (
                      <TextField
                        style={{ width: "200px" }}
                        id="outlined-basic"
                        variant="outlined"
                        defaultValue={data[label]}
                        onChange={(e) => {
                          data[label] = e.target.value;
                          setData(data);
                        }}
                      />
                    )}
                  </TableCell>
                </TableRow>
              ))} */}
            </TableBody>

          </Table>) : ""}
        <Chip
          style={{ marginTop: "10px" }}
          onClick={() => handleSubmit()}
          label="Save changes"
        />
      </DialogContent>
    </Dialog>
  );
}

export default EditProduct;
