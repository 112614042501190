import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "../firebase.js";

import { getFirestore, collection, addDoc, Timestamp } from "firebase/firestore";

function CreateScreen() {
  let navigate = useNavigate();
  const [data, setData] = useState({});

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    setData({ ...data, [name]: value });
  };

  const onClose = () => {
    navigate("/screens");
  };

  // State to store uploaded file
  const [image, setImage] = useState("");
  

  const handleUpload = async (label) => {
    const file = image;
      
    if (!file) {
      alert("Please upload an image first!");
    }
    const uniqueId = Math.random().toString(36).substring(2);
    const storageRef = ref(storage, `screens/${uniqueId}_${file.name}`);
    

    // progress can be paused and resumed. It also exposes progress updates.
    // Receives the storage reference and the file to upload.
    const uploadTask = uploadBytesResumable(storageRef, file);

    await new Promise((resolve, reject) => {
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // const percent = Math.round(
          //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          // );
        },
        (err) => console.log(err),
        () => {
          console.log("uploading");
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            resolve();
            data.filename = url;
            data.storage_ref = uploadTask.snapshot.ref.fullPath;
            data.created = Timestamp.now();
          });
        }
      );
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(data);

    await handleUpload("screen_image");
    
    // Create new product in the products document in firebase
    let screenData = {};
  
    screenData.name = data.name;
    screenData.url = data.filename;
    screenData.storage_ref = data.storage_ref;
    screenData.created = data.created;
    



    await addDoc(collection(getFirestore(), "screens"), screenData)
      .catch((err) => {
        console.log(err);
      })
      .then(() => {
        navigate("/screens");
      });
  };

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle id="alert-dialog-title">
        Add a new screen
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={(e) => handleSubmit(e)}>
          <Table size="small" sx={{ maxWidth: 800 }}>
            <TableBody>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>
                  <TextField
                    style={{ width: "200px" }}
                    name="name"
                    type="text"
                    required
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                  />
                </TableCell>
              </TableRow>
              
              <TableRow>
                <TableCell>Image upload</TableCell>
                <TableCell>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div>
                      <input
                        style={{ marginBottom: "10px" }}
                        type="file"
                        required
                        onChange={(e) => {
                          setImage(e.target.files[0]);
                        }}
                        accept="/image/*"
                      />
                    </div>
                  </div>
                </TableCell>
              
              </TableRow>
            </TableBody>
          </Table>
          <Button
            style={{ marginTop: "20px" }}
            type="submit"
            variant="contained"
          >
            Add screen
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default CreateScreen;
