import {
  getFirestore,
  collection,
  getDocs,
  query,
  deleteDoc,
  doc,
  getDoc,
  orderBy,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import {
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { Outlet, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router'
import MachineMenu from './MachineMenu'


function MachineProducts() {
  let params = useParams();
  const machineId = params.machineId;
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [products, setProducts] = useState({});
  const [productNames, setProductNames] = useState(null);
  const [machine, setMachine] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  const handleClose = () => {
    setOpen(false);
  };

  function fetchData() {
    getDocs(
      query(collection(getFirestore(), `machines/${machineId}/products`),orderBy('display_order', 'asc'))
    ).then(async (querySnapshot) => {
      let products = {};

      // Map each product to a promise that resolves to the name
      const namePromises = querySnapshot.docs.map(async (doc) => {
        let data = doc.data();
        data.id = doc.id;
        products[doc.id] = data;

        if (data.reference) {
          const name = await getDoc(data.reference)
            .then((doc) => {
              return doc.data().name;
            })
            .catch((error) => {
              console.log(error);
              return null;
            });
          return name;
        }
      });

      // Wait for all promises to resolve
      const resolvedNames = await Promise.all(namePromises);

      // Update the state with the resolved names
      setProducts(products);
      setProductNames(resolvedNames);
    });
  }

  useEffect(() => {
    fetchData();
  }, [location.key])


  function fetchMachineData() {

        
    getDoc(doc(getFirestore(), "machines", machineId)).then(
        (docSnap) => {
            let machine = docSnap.data();
            
          setMachine(docSnap.data());
        }
      );



   
    
      
  }
  useEffect(() => {
    fetchData()
    fetchMachineData();
  }, [machineId]);

  const onDelete = async (id) => {
    await deleteDoc(doc(getFirestore(), `machines/${machineId}/products`, id));
    // setProducts((prev) => {
    //   let newProducts = { ...prev };
    //   delete newProducts[id];
    //   return newProducts;
    // });
    fetchData();
  };

  return (
    <div>
              {MachineMenu(machine, machineId)}
<br />
        <Button
        style={{ marginBottom: "20px" }}
        onClick={() => navigate("create")}
        variant="contained"
      >
        Add product
      </Button>
       
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {/* <TableCell>ID</TableCell>*/}
              <TableCell>Order</TableCell> 
              <TableCell>Name</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Discount Price</TableCell>
              <TableCell>Available stock</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {productNames
              ? Object.values(products).map((product, index) => (
                  <TableRow key={product.id}>
                    {/* <TableCell>{product.id}</TableCell>
                    <TableCell>
                      {product.reference
                        ? product.reference.path
                        : "No reference"}
                    </TableCell> */}
                    <TableCell>
                      {product.display_order != null? `${product.display_order}` : '-'}
                    </TableCell>
                    <TableCell>
                      {productNames[index] ? productNames[index] : "No name"}
                    </TableCell>
                    <TableCell>&euro;{product.price}</TableCell>
                    <TableCell>
                      {product.discount_price
                        ? `€${product.discount_price}`
                        : "No discount"}
                    </TableCell>
                    <TableCell>{product.available_stock}</TableCell>
                    <TableCell>
                      <Chip
                        style={{ marginRight: "10px" }}
                        label="Edit"
                        onClick={() => navigate(`edit/${product.id}`)}
                      />
                      <Chip
                        label="Delete"
                        onClick={() => {
                          setDeleteId(product.id);
                          setOpen(true);
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))
              : null}
          </TableBody>
        </Table>
      </TableContainer>
      <Outlet />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete product"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this product?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={() => {
              onDelete(deleteId);
              handleClose();
            }}
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default MachineProducts;
